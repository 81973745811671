
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'

export default defineComponent({
  name: 'Dashboard Mentor',
  data() {
    return {
      masks: {
        weekdays: 'WWW' as string,
      },
      attributes: [] as any,
      attributesFilter: [] as any,
      attributesRequest: [] as any,
      startOfMonth: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endOfMonth: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
      modalDetailWebinar: false,
      detailWebinar: {},
      checkWebinar: true,
      checkWebinarPlus: true,
    }
  },
  mounted() {
    this.monthSchedule()
  },
  methods: {
    changeCheckWebinar(event: any) {
      this.attributesFilter = []
      if (this.checkWebinar == true && this.checkWebinarPlus == true) {
        this.attributesFilter = this.attributes
      } else if (this.checkWebinar == true && this.checkWebinarPlus == false) {
        this.attributesFilter = this.attributes.filter((el: any) => {
          if (el.customData.is_plus == true) {
            return el
          }
        })
      } else if (this.checkWebinar == false && this.checkWebinarPlus == true) {
        this.attributesFilter = this.attributes.filter((el: any) => {
          if (el.customData.is_plus == false) {
            return el
          }
        })
      } else {
        this.attributesFilter = []
      }
    },
    async monthSchedule() {
      this.attributes = []
      this.attributesFilter = []
      await ClassroomModel.getMentorWebinar(this.startOfMonth, this.endOfMonth)
        .then(res => {
          if (res.success) {
            const arr = res.data.sort(function(a: any, b: any) {
              const keyA = new Date(a.started_at),
                keyB = new Date(b.started_at)
              // Compare the 2 dates
              if (keyA < keyB) return -1
              if (keyA > keyB) return 1
              return 0
            })
            const schedule = [] as any[]
            if (arr.length > 0) {
              arr.forEach((el: any, i: number) => {
                const date = moment(el.started_at).format('YYYY-MM-DD 00:00:00')
                const payload = {
                  key: i + 1,
                  customData: el,
                  dates: new Date(date),
                }
                schedule.push(payload)
              })
            }
            this.attributes = schedule
            this.attributesFilter = this.attributes
          } else {
            console.log('gagal')
          }
        })
        .catch(err => {
          console.log('gagal')
        })
    },
    pageChange(event: any) {
      const dateChange = event.year + '-' + event.month + '-1'
      this.startOfMonth = moment(dateChange, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.endOfMonth = moment(dateChange, 'YYYY-MM-DD')
        .endOf('month')
        .format('YYYY-MM-DD')
      this.monthSchedule()
    },
    showDetailWebinar(data: any) {
      this.modalDetailWebinar = true
      this.detailWebinar = data
    },
    hideDetailWebinar() {
      this.modalDetailWebinar = false
      this.detailWebinar = {}
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY')
    },
    formatDateTime(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:ss')
    },
  },
})
