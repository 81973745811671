<template>
  <div class="w-10/12 min-h-screen flex m-auto flex-col">
    <div class="flex justify-between">
      <span class="font-desain font-semibold text-[#212121] text-2xl my-3"
        >Jadwal Mentor</span
      >
      <div class="self-center">
        <div class="flex items-center">
          <input
            type="checkbox"
            id="webinar-plus"
            v-model="checkWebinar"
            @change="changeCheckWebinar($event)"
            name="webinar-plus"
          />
          <label
            for="webinar-plus"
            class="font-solusi text-sm flex m-0 cursor-pointer"
          >
            <div
              class="w-1.5 h-4 rounded-sm ml-2 mr-1 bg-yellow-primary self-center"
            ></div>
            Webinar Plus
          </label>
        </div>
        <div class="flex items-center">
          <input
            type="checkbox"
            id="webinar"
            v-model="checkWebinarPlus"
            @change="changeCheckWebinar($event)"
            name="webinar"
          />
          <label
            for="webinar"
            class="font-solusi text-sm flex m-0 cursor-pointer"
          >
            <div
              class="w-1.5 h-4 rounded-sm ml-2 mr-1 bg-[#009DFF] self-center"
            ></div>
            Webinar Biasa
          </label>
        </div>
      </div>
    </div>
    <div class="custom-calendar mb-5">
      <v-calendar
        class="w-full"
        transition="slide-h"
        :masks="masks"
        :attributes="attributesFilter"
        disable-page-swipe
        is-expanded
        @update:from-page="pageChange"
      >
        <template v-slot:day-content="{ day, attributes }">
          <div class="cal-container">
            <span class="cal-day-label font-solusi">{{ day.day }}</span>
            <div class="mx-1">
              <div
                v-for="(attr, i) in attributes"
                :key="i"
                class="cal-card-event p-0 my-1"
              >
                <div
                  v-if="attr.customData.is_plus"
                  class="btn-webinar-plus p-1 cursor-pointer"
                  @click="showDetailWebinar(attr.customData)"
                  v-tooltip="
                    attr.customData.classroom.name +
                      ' (' +
                      attr.customData.classroom.code +
                      ')'
                  "
                >
                  <p
                    class="class-title mb-0 font-solusi text-[#00446F] font-semibold"
                  >
                    {{ attr.customData.name }}
                  </p>
                  <p class="text-xs cal-card-date font-solusi text-[#383838]">
                    {{
                      attr.customData.started_at.substring(11, 16) +
                        ' - ' +
                        attr.customData.expired_at.substring(11, 16)
                    }}
                    WIB
                  </p>
                </div>
                <div
                  v-else
                  class="btn-webinar p-1 cursor-pointer"
                  @click="showDetailWebinar(attr.customData)"
                  v-tooltip="
                    attr.customData.classroom.name +
                      ' (' +
                      attr.customData.classroom.code +
                      ')'
                  "
                >
                  <p
                    class="class-title mb-0 font-solusi text-[#00446F] font-semibold"
                  >
                    {{ attr.customData.name }}
                  </p>
                  <p class="text-xs cal-card-date font-solusi text-[#383838]">
                    {{
                      attr.customData.started_at.substring(11, 16) +
                        ' - ' +
                        attr.customData.expired_at.substring(11, 16)
                    }}
                    WIB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-calendar>
    </div>
    <transition name="fade">
      <div
        v-if="modalDetailWebinar"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
      appear
    >
      <div
        v-if="modalDetailWebinar"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-3/4 md:w-1/3 my-6 mx-auto">
          <!--content-->
          <div
            v-click-away="hideDetailWebinar"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3 class="text-2xl font-semibold text-[#00446F] font-desain">
                {{ detailWebinar.name }}
              </h3>
              <div
                class="modal-close cursor-pointer z-50 hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="hideDetailWebinar()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="relative p-6 pt-0 flex-auto">
              <div class="mb-3">
                <p class="font-solusi text-[#383838] font-semibold">
                  Kelas:
                </p>
                <p class="font-solusi text-[#383838] font-normal">
                  {{ detailWebinar.classroom.name }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-solusi text-[#383838] font-semibold">
                  Urutan:
                </p>
                <p class="font-solusi text-[#383838] font-normal">
                  {{ detailWebinar.ordering }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-solusi text-[#383838] font-semibold">
                  Room Link:
                </p>
                <a
                  v-if="detailWebinar.room.link"
                  :href="detailWebinar.room.link"
                  target="_blank"
                  class="font-solusi text-blue-500 hover:text-blue-600 font-normal break-words"
                >
                  {{ detailWebinar.room.link }}
                </a>
                <p
                  v-else
                  class="font-solusi text-[#383838] font-normal break-words"
                >
                  -
                </p>
              </div>
              <div class="mb-3">
                <p class="font-solusi text-[#383838] font-semibold">
                  Waktu Berlangsung:
                </p>
                <p class="font-solusi text-[#383838] font-normal">
                  {{ formatDate(detailWebinar?.started_at) }}
                </p>
                <p class="font-solusi text-[#383838] font-normal">
                  {{
                    detailWebinar.started_at.substring(11, 16) +
                      ' - ' +
                      detailWebinar.expired_at.substring(11, 16)
                  }}
                  WIB
                </p>
              </div>

              <!-- <div class="mb-3">
                <p class="font-solusi text-[#383838] font-semibold">
                  Pengajuan Webinar Plus
                </p>
                <p v-if="detailWebinar.is_plus" class="font-solusi text-success font-normal">
                  Bisa mengajukan webinar plus
                </p>
                <p v-else class="font-solusi text-[#DE1306] font-normal">
                  Tidak bisa mengajukan webinar plus
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'

export default defineComponent({
  name: 'Dashboard Mentor',
  data() {
    return {
      masks: {
        weekdays: 'WWW' as string,
      },
      attributes: [] as any,
      attributesFilter: [] as any,
      attributesRequest: [] as any,
      startOfMonth: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endOfMonth: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
      modalDetailWebinar: false,
      detailWebinar: {},
      checkWebinar: true,
      checkWebinarPlus: true,
    }
  },
  mounted() {
    this.monthSchedule()
  },
  methods: {
    changeCheckWebinar(event: any) {
      this.attributesFilter = []
      if (this.checkWebinar == true && this.checkWebinarPlus == true) {
        this.attributesFilter = this.attributes
      } else if (this.checkWebinar == true && this.checkWebinarPlus == false) {
        this.attributesFilter = this.attributes.filter((el: any) => {
          if (el.customData.is_plus == true) {
            return el
          }
        })
      } else if (this.checkWebinar == false && this.checkWebinarPlus == true) {
        this.attributesFilter = this.attributes.filter((el: any) => {
          if (el.customData.is_plus == false) {
            return el
          }
        })
      } else {
        this.attributesFilter = []
      }
    },
    async monthSchedule() {
      this.attributes = []
      this.attributesFilter = []
      await ClassroomModel.getMentorWebinar(this.startOfMonth, this.endOfMonth)
        .then(res => {
          if (res.success) {
            const arr = res.data.sort(function(a: any, b: any) {
              const keyA = new Date(a.started_at),
                keyB = new Date(b.started_at)
              // Compare the 2 dates
              if (keyA < keyB) return -1
              if (keyA > keyB) return 1
              return 0
            })
            const schedule = [] as any[]
            if (arr.length > 0) {
              arr.forEach((el: any, i: number) => {
                const date = moment(el.started_at).format('YYYY-MM-DD 00:00:00')
                const payload = {
                  key: i + 1,
                  customData: el,
                  dates: new Date(date),
                }
                schedule.push(payload)
              })
            }
            this.attributes = schedule
            this.attributesFilter = this.attributes
          } else {
            console.log('gagal')
          }
        })
        .catch(err => {
          console.log('gagal')
        })
    },
    pageChange(event: any) {
      const dateChange = event.year + '-' + event.month + '-1'
      this.startOfMonth = moment(dateChange, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.endOfMonth = moment(dateChange, 'YYYY-MM-DD')
        .endOf('month')
        .format('YYYY-MM-DD')
      this.monthSchedule()
    },
    showDetailWebinar(data: any) {
      this.modalDetailWebinar = true
      this.detailWebinar = data
    },
    hideDetailWebinar() {
      this.modalDetailWebinar = false
      this.detailWebinar = {}
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY')
    },
    formatDateTime(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm:ss')
    },
  },
})
</script>

<style scoped>
.btn-webinar {
  background: #f7fbff;
  border: 1px solid #009dff;
  box-sizing: border-box;
  border-radius: 4px;
}

.btn-webinar:hover {
  background: #d5e4f4;
}

.btn-webinar-plus {
  background: #fffaf1;
  border: 1px solid #ffc200;
  box-sizing: border-box;
  border-radius: 4px;
}

.btn-webinar-plus:hover {
  background: #f0e7d5;
}
/* V-Calendar */
.class-title {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  float: left;
  line-height: 1.2;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
::v-deep(.custom-calendar .vc-container) {
  border-radius: 0.5rem;
  width: 100%;
}
::v-deep(.custom-calendar .vc-header) {
  /* background-color: #009dff; */
  padding: 10px 0;
}
::v-deep(.custom-calendar .vc-container .vc-header) {
  /* background-color: #009dff; */
  padding: 10px 0;
}
::v-deep(.custom-calendar .vc-pane) {
  border-radius: 5px;
}
::v-deep(.vc-title, .vc-day-content) {
  font-family: 'SolusiSans', sans-serif;
}
::v-deep(.custom-calendar .vc-title) {
  color: #383838;
  font-weight: 600;
  font-size: 1.2rem;
}
::v-deep(.custom-calendar .vc-container .vc-weeks) {
  padding: 0;
}
::v-deep(.custom-calendar .vc-container .vc-weekday) {
  background-color: #f6fbff;
  color: #000;
  padding: 10px 0;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}
::v-deep(.custom-calendar .vc-container .vc-day) {
  padding: 0 5px 3px 5px;
  text-align: left;
  min-height: 90px;
  min-width: 90px;
  background-color: white;
}
@media (max-width: 500px) {
  ::v-deep(.custom-calendar .vc-container .vc-day) {
    padding: 0 5px 3px 5px;
    text-align: left;
    min-height: 20px;
    min-width: 20px;
    background-color: white;
    min-height: 75px;
  }
}
::v-deep(.custom-calendar .vc-container .vc-day:not(.on-bottom)) {
  border-bottom: 1px solid #e5e7eb;
}
::v-deep(.custom-calendar .vc-container .vc-day:not(.on-bottom).weekday-1) {
  border-bottom: 1px solid #e5e7eb;
}
::v-deep(.custom-calendar .vc-container .vc-day) {
  border-right: 1px solid #e5e7eb;
  min-height: 90px;
}
::v-deep(.custom-calendar .vc-container .vc-day-dots) {
  margin-bottom: 5px;
}
::v-deep(.custom-calendar .in-next-month.is-not-in-month) {
  display: none;
}
::v-deep(.custom-calendar .vc-container .vc-day .cal-day-label) {
  color: #757575;
}
::v-deep(.custom-calendar .vc-container .on-left .cal-day-label) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .vc-weekday:first-child) {
  color: #de1306;
}
::v-deep(.custom-calendar .vc-container .is-today) {
  background-color: #bde6ff !important;
}
::v-deep(.custom-calendar .vc-container .is-today .cal-day-label) {
  width: fit-content;
  color: #00446f;
  font-weight: 600;
}
::v-deep(.custom-calendar .vc-arrows-container .vc-arrow) {
  color: #009dff;
}
/* ::v-deep(.custom-calendar .vc-arrows-container .vc-arrow:hover) {
  color: #000000;
  background-color: #ffffff;
} */
</style>
